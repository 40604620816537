<template>
  <swiper
    :pagination="false"
    :modules="modules"
    :autoplay="{
      delay: 2500,
      disableOnInteraction: false,
    }"
    :loop="true"
    class="mySwiper"
  >
  <swiper-slide>
      <img
        src="@/assets/sliders/AbrofSlider4.webp"
        alt=""
        class="rounded-sm object-fill"
      />
    </swiper-slide>
    <swiper-slide>
      <img
        src="@/assets/sliders/AbrofSlider1.webp"
        alt="AbrofSlider1"
        class="rounded-sm object-fill"
        width="100"
        height="100"
      />
    </swiper-slide>
    <swiper-slide>
      <img
        src="@/assets/sliders/AbrofSlider2.webp"
        alt="AbrofSlider2"
        class="rounded-sm object-fill"
        width="100"
        height="100"
      />
    </swiper-slide>
   
  </swiper>
</template>
  <script>
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue";

// Import Swiper styles
import "swiper/css";

import "swiper/css/pagination";

// import required modules
import { Pagination, Autoplay } from "swiper";

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    return {
      modules: [Pagination, Autoplay],
    };
  },
};
</script>
  <style scoped>
.swiper-pagination-bullet-active {
  opacity: var(--swiper-pagination-bullet-opacity, 1);
  background: #c43670 !important;
}
.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
</style>